import React from "react";
import ContactPage from "../../components/Frontend/Contactus/ContactUs"


const  Contact = () => {
    return (
        <div>
            <ContactPage/>
        </div>
    )
}


export default Contact;