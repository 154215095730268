import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";

const Admin = [
  [
    "Courses",
    "/Layout/Admin",
    <SettingsIcon sx={{ fontSize: "2rem" }}></SettingsIcon>,
    
  ],
];

export default Admin;
