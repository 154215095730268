import React from "react";


import Payment from "../../components/Frontend/Payment";
import Layout from "../../components/Layout/Frontend/Layout";


const Home = () => {
  return (
    <div>
      {/* <Layout> */}
        <Payment></Payment>
      {/* </Layout> */}
    </div>
  );
};

export default Home;
