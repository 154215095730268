import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Footer from "../../Frontend/Footer/Footer";
import ResponsiveAppBar from "../../Frontend/NavBar/navbar";
import { Box } from "@mui/material";
function Layout(props) {
  const { bgColor = "" } = props;
  return (
    <Box component={"main"}>
      <ResponsiveAppBar></ResponsiveAppBar>
      {props.children}
      <Footer bgColor={bgColor}></Footer>
      <div>
        <MessengerCustomerChat
          pageId="100823749735658"
          appId="639781087735470"
          shouldShowDialog={true}
          autoLogAppEvents={true}
        />
      </div>
    </Box>
  );
}

export default Layout;
