// icon
import GradingIcon from "@mui/icons-material/Grading";
import SettingsIcon from "@mui/icons-material/Settings";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import React from "react";

function OfferPte() {
  //custom css and responsive value
  const offerBox = {
    textAlign: "center",
    px: 3,
  };
  const hStyle = {
    fontSize: {
      xs: "15px",
      sm: "17px",
      md: "1.7rem",
    },
    fontWeight: 600,
    py: "1rem",
  };
  const pStyle = {
    width: "100%",
    color: "#757575",
    fontSize: {
      xs: "0.8rem",
      sm: "1rem",
      md: "0.9rem",
      lg: "1.2rem",
    },
    mb: 5,
  };

  const iconBox = {
    width: "100%",
    p: {
      md: 3,
      sm: 1,
      xs: 0,
    },

    mb: 5,
  };

  const iconStyle = {
    padding: "1rem",
    color: "#fff",
    fontSize: {
      xs: "5rem",
      sm: "5rem",
      md: "8rem",
    },
    // backgroundColor: "rgb(0,89,171)",
    backgroundColor: "#0CAFFF",
    borderRadius: "0.5rem",
    boxShadow: 3,
  };

  const titleStyle = {
    fontSize: {
      xs: "16px",
      sm: "17px",
      md: "1.3rem",
    },
    fontWeight: 500,
    py: {
      xs: 0.5,
      sm: 1,
      md: 2,
    },
  };
  return (
    <>
      {/* offerBox start */}
      <Box sx={{ ...offerBox, pt: 10 }}>
        {/* title  */}
        <Typography variant="h3" component="h3" sx={{ ...hStyle }}>
          WHAT WE OFFER YOU
        </Typography>
        {/* info */}
        <Typography variant="p" component="p" sx={{ ...pStyle }}>
          PTE Tools offers you the biggest real exam question bank on the
          market. New questions and audios are
          <br /> updated regularly and fastest. Additionally, our tool’s
          features and layouts are designed by top-notch UX
          <br /> programmers, particularly user-friendly and easy to use.
        </Typography>
        {/* offer icon and info grid start */}
        <Grid
          container
          justify="space-around"
          direction={{
            md: "row",
            sm: "row",
            xs: "column",
          }}
          alignItems="center"
          flexWrap="wrap"
          width={1}
          marginTop={5}
          sx={{
            "& .MuiGrid-container": {
              margin: "0 auto",
            },
          }}
        >
          {/* offer icon and info content */}
          <Grid item xs={0} md={0} lg={0} xl={2.25}></Grid>
          <Grid item xs={4} md={4} lg={4} xl={2.5}>
            <Box sx={{ ...iconBox }}>
              <GradingIcon sx={{ ...iconStyle }}></GradingIcon>
              <Typography variant="h5" sx={{ ...titleStyle }} component="h5">
                Mock test platform
              </Typography>
              <Typography variant="p" component="p" sx={{ ...pStyle }}>
                To boost students’ confidence, we offer mock test platform with
                real question banks .
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4} lg={4} xl={2.5}>
            <Box sx={{ ...iconBox }}>
              <SettingsIcon sx={{ ...iconStyle }}></SettingsIcon>
              <Typography variant="h5" sx={{ ...titleStyle }} component="h5">
                UI/UX Setting
              </Typography>
              <Typography variant="p" component="p" sx={{ ...pStyle }}>
                Smart and user friendly interface, smooth and frictionless
                operation system guaranteed.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4} lg={4} xl={2.5}>
            <Box sx={{ ...iconBox }}>
              <TextSnippetIcon sx={{ ...iconStyle }}></TextSnippetIcon>
              <Typography variant="h5" sx={{ ...titleStyle }} component="h5">
                Strategy, tips & tricks
              </Typography>
              <Typography variant="p" sx={{ ...pStyle }} component="p">
                We provide coaching with effective , efficient learning
                strategy, tips and tricks for PTE.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={0} md={0} lg={0} xl={2.25}></Grid>
        {/* offer icon and info grid end */}
      </Box>
      {/* offerBox end */}
    </>
  );
}

export default OfferPte;
