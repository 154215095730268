import { Box,  Pagination, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TableForm from "../../../../components/Backend/TableForm/TableForm";
import { fetchScoreMtPostsAsync } from "../../../../redux/thunk/Posts";
import ActionCellForList from "../../../../components/Backend/ActionCellForList";
import TableListLoadingComponent from "../../../../components/Backend/Admin/Posts/TableListLoadingComponent";
import TableListFailedComponent from "../../../../components/Backend/Admin/Posts/TableListFailedComponent";

const MockTestScoreList = () => {
  const { scoreMtPosts, scoreMtStatus } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  let [page, setPage] = useState(1);
  let postPath = "mt-score?page=" + page;
  let [searchValue, setSearchValue] = useState("");

  let searchPath =
    "search-mt-by-name?page=" + page + "&q=" + searchValue + "&filter_type=s";
  useEffect(() => {
    if (searchValue !== "") {
      dispatch(fetchScoreMtPostsAsync(searchPath));
    } else {
      dispatch(fetchScoreMtPostsAsync(postPath));
    }
  }, [dispatch, postPath, page, searchValue, searchPath]);

  let handleChange = (event, p) => {
    setPage(p);
  };

  var post_html_table = "";
  var paging_html = "";

  const backendURL = process.env.REACT_APP_BACKEND_ADMIN + "mt-score";

  if (scoreMtStatus === "loading") {
    post_html_table = <TableListLoadingComponent />;
  }
  if (scoreMtStatus === "failed") {
    post_html_table = <TableListFailedComponent />;
  } else {
    if (scoreMtPosts.data !== undefined) {
      post_html_table = scoreMtPosts.data.map((item, index) => {
        return (
          <TableRow
            id={item.id}
            key={index}
            sx={{
              borderBottom: 2,
              "& .MuiTableCell-root": {
                textAlign: "center",
              },

              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell>{new Date(item?.created_at).toDateString()}</TableCell>
            <TableCell>{item.username}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>
              {item.mt_type_id === 1
                ? "Full Mock Test"
                : item.mt_type_id === 2
                ? "Mini Mock Test"
                : item.mt_type_id === 3
                ? "Speaking Mock Test"
                : item.mt_type_id === 4
                ? "Reading Mock Test"
                : item.mt_type_id === 5
                ? "Listening Mock Test"
                : item.mt_type_id === 6
                ? "Writing Mock Test"
                : ""}
            </TableCell>
            <TableCell>{item.overall_point}</TableCell>
            <ActionCellForList
              to="detail"
              leftTooltip="Approve / Reject"
              url={backendURL}
              item={item}
            />
          </TableRow>
        );
      });
    }
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            ml: "2rem",
            top: "1rem",

            position: "absolute",
            overflow: "visible",
            zIndex: 1500,
          }}
        >
          <Typography variant="h5">Score Mock Test</Typography>
        </Box>
        <TableForm
          tableSection="scoremt"
          pagingHtml={paging_html}
          postHtmlTable={post_html_table}
          setPage={setPage}
          setSearchValue={setSearchValue}
        ></TableForm>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            my: 2,
          }}
        >
          <Pagination
            size="large"
            count={scoreMtPosts.last_page}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default MockTestScoreList;
