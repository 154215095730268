import * as React from "react";
import MailSent from "../MailSentPage/MailSent";

export default function MediaCard() {
  return (
    <>
      <MailSent page="verifyPage"></MailSent>
    </>
  );
}
