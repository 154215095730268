import { createSlice } from "@reduxjs/toolkit";

// import { fetchAllCountryAsync, fetchAllUserAsync, fetchUserAsync, fetchUserDetailsAsync, fetchUserPlanDetailFromAdminPanelAsync } from "../api/UserApi";
import {
  fetchAllCountryAsync,
  fetchAllUserAsync,
  fetchUserAsync,
  fetchUserDetailsAsync,
  fetchUserPlanDetailFromAdminPanelAsync,
} from "../thunk/Users";
import { getCookie } from "../../Utils/GetCookies";
import { Crisp } from "crisp-sdk-web";

// initialize userToken from local storage
const userToken = getCookie("userToken") ? getCookie("userToken") : null;

const userId = localStorage.getItem("userId")
  ? localStorage.getItem("userId")
  : null;

const initialState = {
  loading: true,
  userToken,
  userId,
  error: null,
  success: false,
  status: null,
  user: null,
  role: null,
  userList: [],
  country: null,
  countryError: null,
  allCountryLoading: true,
  allCountryStatus: null,
  allCountryError: null,
  allCountry: null,
  userDetailsStatus: null,
  userDetailsError: null,
  userDetails: null,

  userPlanDetailStatus: null,
  userPlanDetailError: null,
  userPlanDetail: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogout: (state) => {
      state.loading = false;
      state.userList = [];
      state.success = null;
      state.user = null;
      state.role = null;
      state.error = null;
      state.country = "AUD";
      Crisp.setTokenId(); // 1. Clear the token value
      Crisp.session.reset(); // 2. Unbind the current session
    },
    setCountry: (state, action) => {
      localStorage.setItem("country", action.payload);
      state.country = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserAsync.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    });
    builder.addCase(fetchUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "succeeded";
      state.user = action.payload;
      state.role = action.payload.data.role_id;
      // state.country = action.payload.data.country;
      // localStorage.setItem("country", action.payload.data.country);
    });
    builder.addCase(fetchUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.status = "failed";
      state.error = action.error.message;
    });
    //userDetails
    builder.addCase(fetchUserDetailsAsync.pending, (state) => {
      state.userDetailsStatus = "loading";
      state.userDetailsError = null;
    });
    builder.addCase(fetchUserDetailsAsync.fulfilled, (state, action) => {
      state.userDetailsStatus = "succeeded";
      state.userDetails = action.payload;
      state.userDetailsError = null;

      // state.country = action.payload.data.country;
      // localStorage.setItem("country", action.payload.data.country);
    });
    builder.addCase(fetchUserDetailsAsync.rejected, (state, action) => {
      state.userDetailsStatus = "failed";
      state.userDetailsError = action.error.message;
    });

    builder.addCase(fetchAllUserAsync.pending, (state, action) => {
      state.loading = true;
      state.status = "loading";
    });
    builder.addCase(fetchAllUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "succeeded";
      state.userList = action.payload;
    });
    builder.addCase(fetchAllUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.status = "failed";
      state.error = action.error.message;
    });

    builder.addCase(fetchAllCountryAsync.pending, (state, action) => {
      state.allCountryLoading = true;
      state.allCountryStatus = "loading";
    });
    builder.addCase(fetchAllCountryAsync.fulfilled, (state, action) => {
      state.allCountryLoading = false;
      state.allCountryStatus = "succeeded";
      state.allCountry = action.payload;
    });
    builder.addCase(fetchAllCountryAsync.rejected, (state, action) => {
      state.allCountryLoading = false;
      state.allCountryStatus = "failed";
      state.allCountryError = action.error.message;
    });

    //user plan detail
    builder.addCase(fetchUserPlanDetailFromAdminPanelAsync.pending, (state) => {
      state.userPlanDetailStatus = "loading";
      state.userPlanDetailError = null;
    });
    builder.addCase(
      fetchUserPlanDetailFromAdminPanelAsync.fulfilled,
      (state, action) => {
        state.userPlanDetailStatus = "succeeded";

        state.userPlanDetail = action.payload;
        state.userPlanDetailError = null;
      }
    );
    builder.addCase(
      fetchUserPlanDetailFromAdminPanelAsync.rejected,
      (state, action) => {
        state.userPlanDetailStatus = "failed";
        state.userPlanDetailError = action.error.message;
      }
    );
  },
});

export const { userLogout, setCountry } = userSlice.actions;
export default userSlice.reducer;
