import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Carousel } from "react-responsive-carousel";

import "./carouselCustom.css";
import "react-responsive-carousel/lib/styles/carousel.css";
import { useNavigate } from "react-router-dom";

function CarouselComponent() {
  const navigate = useNavigate();
  const frontEndURL = process.env.REACT_APP_FRONTEND_URL;
  const hStyle = {
    color: "white",
    fontSize: {
      xs: "15px",
      sm: "17px",
      md: "1.7rem",
      lg: "2rem",
      xl: "2.5rem",
    },
    textAlign: "left",
    fontWeight: 600,

    py: {
      xs: "0rem",
      sm: "0.25rem",
      md: "0.5rem",
    },
  };
  const buttonStyle = {
    justifyContent: "flex-start",
    color: "#757575",
    backgroundColor: "yellow",
    cursor: "pointer",

    "&:hover": {
      color: "black",
      backgroundColor: "white",
    },
  };
  const pStyle = {
    fontSize: {
      xs: "0.5rem",
      sm: "0.7rem",
      md: "1rem",
      lg: "1.2rem",
      xl: "1.5rem",
    },
  };
  return (
    <div>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        className="pte-start-carousel"
        autoPlay={false}
        infiniteLoop={true}
        animationHandler={"fade"}
        interval={3000}
      >
        {/* <div className="seven"> */}
        <img
            // src="http://localhost:3000/Image/BannerImg1.png"
            src={`${frontEndURL}Image/NewBanner.png`}
            style={{ height: "100%" }}
            alt=""
          />
        <img
          // src="http://localhost:3000/Image/BannerImg1.png"
          src={`${frontEndURL}Image/15days.png`}
          style={{ height: "100%" }}
          alt=""
        />
        <img
          // src="http://localhost:3000/Image/BannerImg1.png"
          src={`${frontEndURL}Image/AigmaSectionBanner.png`}
          style={{ height: "100%" }}
          alt=""
        />
        {/* <section className="pte-button">
            <h2>
              BEST PRACTICE PLATFORM FOR <br />
              PTE ACADEMIC
            </h2>
            <h5>HIGH SCORE GUARANTEED</h5>
            <Button
              sx={{ backgroundColor: "yellow", color: "black", mt: 2 }}
              variant="contained"
              className="btn"
            >
              GET STARTED
            </Button>
          </section> */}
        {/* </div> */}
        <div className="bg-banner">
          <img
            // src="http://localhost:3000/Image/Banner.png"
            src={`${frontEndURL}Image/Banner.png`}
            style={{ height: "100%" }}
            alt="banner"
            loading="lazy"
          />

          <Box
            sx={{
              position: "absolute",
              top: {
                xs: "60%",
                sm: "60%",
                md: "60%",
              },
              left: "43%",
              zIndex: 1900,
            }}
            className="pte-button"
          >
            <Button
              variant="contained"
              sx={{ ...buttonStyle, zIndex: 2500 }}
              onClick={() => navigate("/login")}
            >
              <Typography sx={{ ...pStyle }}>
                Join Now For{" "}
                <span style={{ color: "red", fontWeight: "600" }}>FREE</span>
              </Typography>
            </Button>
          </Box>
        </div>

        <div className="one">
          <img
            // src="http://localhost:3000/Image/BannerImg1.png"
            src={`${frontEndURL}Image/BannerImg1.png`}
            style={{ height: "100%" }}
            alt="banner"
            loading="lazy"
          />
          {/* <section className="pte-button">
            <h2>
              BEST PRACTICE PLATFORM FOR <br />
              PTE ACADEMIC
            </h2>
            <h5>HIGH SCORE GUARANTEED</h5>
            <Button
              sx={{ backgroundColor: "yellow", color: "black", mt: 2 }}
              variant="contained"
              className="btn"
            >
              GET STARTED
            </Button>
          </section> */}
        </div>
        <div className="two">
          <img
            // src="http://localhost:3000/Image/BannerImg2.png"
            src={`${frontEndURL}Image/BannerImg2.png`}
            style={{ height: "100%" }}
            alt="banner"
            loading="lazy"
          />
        </div>
        <div className="three">
          <img
            // src="http://localhost:3000/Image/BannerImg3.png"
            src={`${frontEndURL}Image/BannerImg3.png`}
            style={{ height: "100%" }}
            alt="banner"
            loading="lazy"
          />
        </div>
        <div className="four">
          <img
            // src="http://localhost:3000/Image/BannerImg4.png"
            src={`${frontEndURL}Image/BannerImg4.png`}
            style={{ height: "100%" }}
            alt="banner"
            loading="lazy"
          />
        </div>
        <div className="five">
          <img
            // src="http://localhost:3000/Image/BannerImg5.png"
            src={`${frontEndURL}Image/BannerImg5.png`}
            style={{ height: "100%" }}
            alt="banner"
            loading="lazy"
          />
        </div>
        <div className="six">
          <img
            // src="http://localhost:3000/Image/BannerImg6.png"
            src={`${frontEndURL}Image/BannerImg6.png`}
            style={{ height: "100%" }}
            alt="banner"
            loading="lazy"
          />
        </div>
        {/* carousel items end */}
      </Carousel>
    </div>
  );
}

export default CarouselComponent;
