import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EmailIcon from "@mui/icons-material/Email";
import { InputAdornment, TextField, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import * as React from "react";
import { useEffect } from "react";

import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emailVerify } from "../../../features/auth/authActions";
// import { fetchUserAsync } from "../../../redux/thunk/users";
import { FormStyle } from "../FormStyle";
import ReusableLayout from "./ReusableLayout";
import Spinner from "../../Spinner";

export default function EmailVerify() {
  let { rpLoading, userInfo, rpError } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();
  let [clicked, setClicked] = useState(false);

  const navigate = useNavigate();

  //  redirect authenticated user to profile screen
  useEffect(() => {
    if (clicked) {
      if (rpError === null) {
        if (userInfo) {
          navigate("/admin/dashboard");
          setClicked(false);
        }
      } else {
        reset();
        setClicked(false);
      }
    }
  }, [navigate, clicked, userInfo, rpError, reset]);

  const submitForm = (data) => {
    console.log(data);
    setClicked(true);

    dispatch(emailVerify(data));
  };
  return (
    <ReusableLayout
      title="Email Verification"
      loading={rpLoading}
      backPath="/logIn"
    >
      <form onSubmit={handleSubmit(submitForm)}>
        {/* React hook form with material ui */}

        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message: "*email is required",
            },
          }}
          defaultValue=""
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              inputRef={ref}
              id="email"
              type="email"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              label="Email"
              sx={{ my: 1, bgcolor: "rgb(231 239 254)" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ width: "25%" }}>
                    <Box
                      sx={{
                        ...FormStyle.inputIcon,
                      }}
                      aria-label="lock icon"
                    >
                      <EmailIcon></EmailIcon>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {errors.email && (
          <Typography variant="p" color="red" textAlign={"left"}>
            <small>{errors.email.message}</small>
          </Typography>
        )}

        <Controller
          name="security_code"
          control={control}
          rules={{
            required: {
              value: true,
              message: "*code is required",
            },
          }}
          defaultValue=""
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              inputRef={ref}
              id="code"
              type="number"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              label="Code"
              sx={{ my: 1, bgcolor: "rgb(231 239 254)" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ width: "25%" }}>
                    <Box
                      sx={{
                        ...FormStyle.inputIcon,
                      }}
                      aria-label="lock icon"
                    >
                      <ConfirmationNumberIcon></ConfirmationNumberIcon>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {errors.code && (
          <Typography variant="p" color="red" textAlign={"left"}>
            <small>{errors.code.message}</small>
          </Typography>
        )}
        <Box sx={{ width: "100%", display: "flex" }}>
          <Button
            variant="contained"
            type="submit"
            className="button"
            disabled={rpLoading}
            sx={{ position: "absolute", right: 15 }}
          >
            {rpLoading ? <Spinner /> : "Verify"}
          </Button>
        </Box>
        <Box sx={{ textAlign: "left" }}>
          <Link
            underline="none"
            onClick={() => navigate("/resend/verification-code")}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "black",
              },
            }}
          >
            <Typography variant="p" sx={{ pl: 0.5 }}>
              Resend Verification Code
            </Typography>
          </Link>
        </Box>
      </form>
    </ReusableLayout>
  );
}
