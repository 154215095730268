export const DialogStyle = {
  table: {
    width: "100%",
    "& .table": {
      width: "100%",
    },
    boxShadow: "",
    border: "1px solid black",
  },
};
