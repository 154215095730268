import { Box } from "@mui/system";
import React from "react";

import OfferPte from "./OfferPte";
import Subscription from "./Subscription";

function LayoutOS() {
  //custom css and responsive value
  const layoutStyle = {
    backgroundColor: "rgb(227,242,253)",
    width: "100%",
  };
  return (
    <>
      {/* layout box for offer and subscription section */}
      <Box
        sx={{
          ...layoutStyle,
        }}
      >
        <OfferPte></OfferPte>

      </Box>
    </>
  );
}

export default LayoutOS;
