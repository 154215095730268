const userId = localStorage.getItem("userId");

export const savedCurrentPost = (category, index) => {
  localStorage.setItem("saveCategory", category);
  localStorage.setItem("saveIndex", index);
};

export const saveData = (
  mtId,
  mtType,
  time,
  page,
  state,
  category,
  index,
  finishedTestData,
  //for writing section in which times are limited and no wifi in deep into the questions can be disrupt the exam flow
  detailAnswer,
  detailDuration
) => {
  let savedMtData = {
    mtId: mtId,
    mtType: mtType,
    time: time,
    page: page,
    speakingState: mtType === 1 || mtType === 3 ? state.speakingState : "",
    readingState: mtType === 1 || mtType === 4 ? state.readingState : "",
    listeningState: mtType === 1 || mtType === 5 ? state.listeningState : "",
    writingState: mtType === 1 || mtType === 6 ? state.writingState : "",
    category: category,
    index: index,
  };

  if (mtType === 1 || mtType === 3) {
    switch (category) {
      case "ra":
        savedMtData.ra = finishedTestData["ra"];
        break;
      case "rs":
        savedMtData.ra = finishedTestData["ra"];
        savedMtData.rs = finishedTestData["rs"];
        break;
      case "di":
        savedMtData.ra = finishedTestData["ra"];
        savedMtData.rs = finishedTestData["rs"];
        savedMtData.di = finishedTestData["di"];
        break;
      case "rl":
        savedMtData.ra = finishedTestData["ra"];
        savedMtData.rs = finishedTestData["rs"];
        savedMtData.di = finishedTestData["di"];
        savedMtData.rl = finishedTestData["rl"];
        break;
      case "asq":
        savedMtData.ra = finishedTestData["ra"];
        savedMtData.rs = finishedTestData["rs"];
        savedMtData.di = finishedTestData["di"];
        savedMtData.rl = finishedTestData["rl"];
        savedMtData.asq = finishedTestData["asq"];
        break;

      default:
        savedMtData.ra = finishedTestData["ra"];
        savedMtData.rs = finishedTestData["rs"];
        savedMtData.di = finishedTestData["di"];
        savedMtData.rl = finishedTestData["rl"];
        savedMtData.asq = finishedTestData["asq"];
        break;
    }
  }

  if ((mtType === 1 && state.speakingState === false) || mtType === 6) {
    switch (category) {
      case "swt":
        savedMtData.swt = finishedTestData["swt"];
        break;
      case "we":
        savedMtData.swt = finishedTestData["swt"];
        savedMtData.we = finishedTestData["we"];
        break;
      default:
        savedMtData.swt = finishedTestData["swt"];
        savedMtData.we = finishedTestData["we"];
        break;
    }
    savedMtData.detailAnswer = detailAnswer;
    savedMtData.detailDuration = detailDuration;
  }
  if ((mtType === 1 && state.writingState === false) || mtType === 4) {
    switch (category) {
      case "rsmc":
        savedMtData.rsmc = finishedTestData["rsmc"];
        break;
      case "rmc":
        savedMtData.rsmc = finishedTestData["rsmc"];
        savedMtData.rmc = finishedTestData["rmc"];
        break;
      case "rop":
        savedMtData.rsmc = finishedTestData["rsmc"];
        savedMtData.rmc = finishedTestData["rmc"];
        savedMtData.rop = finishedTestData["rop"];
        break;
      case "rfib":
        savedMtData.rsmc = finishedTestData["rsmc"];
        savedMtData.rmc = finishedTestData["rmc"];
        savedMtData.rop = finishedTestData["rop"];
        savedMtData.rfib = finishedTestData["rfib"];
        break;

      default:
        savedMtData.rsmc = finishedTestData["rsmc"];
        savedMtData.rmc = finishedTestData["rmc"];
        savedMtData.rop = finishedTestData["rop"];
        savedMtData.rfib = finishedTestData["rfib"];
        savedMtData.rwfib = finishedTestData["rwfib"];
        break;
    }
  }
  if ((mtType === 1 && state.readingState === false) || mtType === 5) {
    switch (category) {
      case "sst":
        savedMtData.sst = finishedTestData["sst"];
        break;
      case "mc":
        savedMtData.sst = finishedTestData["sst"];
        savedMtData.mc = finishedTestData["mc"];
        break;
      case "fib":
        savedMtData.sst = finishedTestData["sst"];
        savedMtData.mc = finishedTestData["mc"];
        savedMtData.fib = finishedTestData["fib"];
        break;
      case "hcs":
        savedMtData.sst = finishedTestData["sst"];
        savedMtData.mc = finishedTestData["mc"];
        savedMtData.fib = finishedTestData["fib"];
        savedMtData.hcs = finishedTestData["hcs"];
        break;
      case "smc":
        savedMtData.sst = finishedTestData["sst"];
        savedMtData.mc = finishedTestData["mc"];
        savedMtData.fib = finishedTestData["fib"];
        savedMtData.hcs = finishedTestData["hcs"];
        savedMtData.smc = finishedTestData["smc"];
        break;
      case "smw":
        savedMtData.sst = finishedTestData["sst"];
        savedMtData.mc = finishedTestData["mc"];
        savedMtData.fib = finishedTestData["fib"];
        savedMtData.hcs = finishedTestData["hcs"];
        savedMtData.smc = finishedTestData["smc"];
        savedMtData.smw = finishedTestData["smw"];
        break;
      case "hiw":
        savedMtData.sst = finishedTestData["sst"];
        savedMtData.mc = finishedTestData["mc"];
        savedMtData.fib = finishedTestData["fib"];
        savedMtData.hcs = finishedTestData["hcs"];
        savedMtData.smc = finishedTestData["smc"];
        savedMtData.smw = finishedTestData["smw"];
        savedMtData.hiw = finishedTestData["hiw"];
        break;

      default:
        savedMtData.sst = finishedTestData["sst"];
        savedMtData.mc = finishedTestData["mc"];
        savedMtData.fib = finishedTestData["fib"];
        savedMtData.hcs = finishedTestData["hcs"];
        savedMtData.smc = finishedTestData["smc"];
        savedMtData.smw = finishedTestData["smw"];
        savedMtData.hiw = finishedTestData["hiw"];
        savedMtData.wfd = finishedTestData["wfd"];
        break;
    }
    savedMtData.detailAnswer = detailAnswer;
    savedMtData.detailDuration = detailDuration;
  }
  const userId = localStorage.getItem("userId");
  localStorage.setItem(userId + "saveMt" + mtId, JSON.stringify(savedMtData));
};

export const updateSavedData = (savedData, mtType) => {
  let data = JSON.parse(savedData);
  let state = {
    speakingState: data.speakingState,
    readingState: data.readingState,
    writingState: data.writingState,
    listeningState: data.listeningState,
  };

  if (mtType === 1 || mtType === 3) {
    switch (data.category) {
      case "ra":
        localStorage.setItem("ra", data.ra);
        break;
      case "rs":
        localStorage.setItem("ra", data.ra);
        localStorage.setItem("rs", data.rs);
        break;
      case "di":
        localStorage.setItem("ra", data.ra);
        localStorage.setItem("rs", data.rs);
        localStorage.setItem("di", data.di);
        break;
      case "rl":
        localStorage.setItem("ra", data.ra);
        localStorage.setItem("rs", data.rs);
        localStorage.setItem("di", data.di);
        localStorage.setItem("rl", data.rl);
        break;
      case "asq":
        localStorage.setItem("ra", data.ra);
        localStorage.setItem("rs", data.rs);
        localStorage.setItem("di", data.di);
        localStorage.setItem("rl", data.rl);
        localStorage.setItem("asq", data.asq);
        break;

      default:
        localStorage.setItem("ra", data.ra);
        localStorage.setItem("rs", data.rs);
        localStorage.setItem("di", data.di);
        localStorage.setItem("rl", data.rl);
        localStorage.setItem("asq", data.asq);
        break;
    }
  }
  if ((mtType === 1 && data.speakingState === false) || mtType === 6) {
    switch (data.category) {
      case "swt":
        localStorage.setItem("swt", data.swt);
        break;
      case "we":
        localStorage.setItem("swt", data.swt);
        localStorage.setItem("we", data.we);
        break;
      default:
        localStorage.setItem("swt", data.swt);
        localStorage.setItem("we", data.we);

        break;
    }
    localStorage.setItem("detailAnswer", data?.detailAnswer);
    localStorage.setItem("detailDuration", data?.detailDuration);
  }
  if ((mtType === 1 && data.writingState === false) || mtType === 4) {
    switch (data.category) {
      case "rsmc":
        localStorage.setItem("rsmc", data.rsmc);
        break;
      case "rmc":
        localStorage.setItem("rsmc", data.rsmc);
        localStorage.setItem("rmc", data.rmc);
        break;
      case "rop":
        localStorage.setItem("rsmc", data.rsmc);
        localStorage.setItem("rmc", data.rmc);
        localStorage.setItem("rop", data.rop);
        break;
      case "rfib":
        localStorage.setItem("rsmc", data.rsmc);
        localStorage.setItem("rmc", data.rmc);
        localStorage.setItem("rop", data.rop);
        localStorage.setItem("rfib", data.rfib);
        break;
      case "rwfib":
        localStorage.setItem("rsmc", data.rsmc);
        localStorage.setItem("rmc", data.rmc);
        localStorage.setItem("rop", data.rop);
        localStorage.setItem("rfib", data.rfib);
        localStorage.setItem("rwfib", data.rwfib);
        break;
      default:
        localStorage.setItem("rsmc", data.rsmc);
        localStorage.setItem("rmc", data.rmc);
        localStorage.setItem("rop", data.rop);
        localStorage.setItem("rfib", data.rfib);
        localStorage.setItem("rwfib", data.rwfib);

        break;
    }
  }
  if ((mtType === 1 && data.readingState === false) || mtType === 5) {
    switch (data.category) {
      case "sst":
        localStorage.setItem("sst", data.sst);
        break;
      case "mc":
        localStorage.setItem("sst", data.sst);
        localStorage.setItem("mc", data.mc);
        break;
      case "fib":
        localStorage.setItem("sst", data.sst);
        localStorage.setItem("mc", data.mc);
        localStorage.setItem("fib", data.fib);
        break;
      case "hcs":
        localStorage.setItem("sst", data.sst);
        localStorage.setItem("mc", data.mc);
        localStorage.setItem("fib", data.fib);
        localStorage.setItem("hcs", data.hcs);
        break;
      case "smc":
        localStorage.setItem("sst", data.sst);
        localStorage.setItem("mc", data.mc);
        localStorage.setItem("fib", data.fib);
        localStorage.setItem("hcs", data.hcs);
        localStorage.setItem("smc", data.smc);
        break;
      case "smw":
        localStorage.setItem("sst", data.sst);
        localStorage.setItem("mc", data.mc);
        localStorage.setItem("fib", data.fib);
        localStorage.setItem("hcs", data.hcs);
        localStorage.setItem("smc", data.smc);
        localStorage.setItem("smw", data.smw);
        break;
      case "hiw":
        localStorage.setItem("sst", data.sst);
        localStorage.setItem("mc", data.mc);
        localStorage.setItem("fib", data.fib);
        localStorage.setItem("hcs", data.hcs);
        localStorage.setItem("smc", data.smc);
        localStorage.setItem("smw", data.smw);
        localStorage.setItem("hiw", data.hiw);
        break;
      case "wfd":
        localStorage.setItem("sst", data.sst);
        localStorage.setItem("mc", data.mc);
        localStorage.setItem("fib", data.fib);
        localStorage.setItem("hcs", data.hcs);
        localStorage.setItem("smc", data.smc);
        localStorage.setItem("smw", data.smw);
        localStorage.setItem("hiw", data.hiw);
        localStorage.setItem("wfd", data.wfd);
        break;
      default:
        localStorage.setItem("sst", data.sst);
        localStorage.setItem("mc", data.mc);
        localStorage.setItem("fib", data.fib);
        localStorage.setItem("hcs", data.hcs);
        localStorage.setItem("smc", data.smc);
        localStorage.setItem("smw", data.smw);
        localStorage.setItem("hiw", data.hiw);
        localStorage.setItem("wfd", data.wfd);

        break;
    }
    // localStorage.setItem("detailAnswer", data?.detailAnswer);
    // localStorage.setItem("detailDuration", data?.detailDuration);
  }
  localStorage.setItem("saveCategory", data.category);
  localStorage.setItem("saveIndex", data.index);
  let returnData = {
    time: data.time,
    page: data.page,
    state: state,
  };

  return returnData;
};
