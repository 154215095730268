import React from "react";
import AboutUsPage from "../../components/Frontend/Aboutus/AboutUs";


const  About = () => {
    return (
        <div>
           <AboutUsPage/>
        </div>
    )
}


export default About;