import React from "react";

const  ContactPage = () => {
    return (
        <div>
            <h3>This is Contact Page.</h3>
        </div>
    )
}


export default ContactPage;