import React from "react";

import ReusableEdit from "../../Subscription/ReusableEdit";


function Edit() {
  return <ReusableEdit title={"Promotion"}></ReusableEdit>;
}

export default Edit;
