import React from "react";

const  AboutPage = () => {
    return (
        <div>
            <h3>This is About Page.</h3>
        </div>
    )
}


export default AboutPage;